@import "./src/assets/scss/main.scss";













































































































































































































































































.cabinet {
    display: flex;
    justify-content: space-between;
}

.container {}

.cabinet {
    margin-top: 85px;
    padding-bottom: 58px;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 50px 60px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 10px;
        }

    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {

    background: #F5F5F5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

// .cabinet {

//     &__content {}

//     &__header {}
// }
.table {
    font-family: Montserrat;
    font-size: 14px;
}

.toggle {
    border: solid #777777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

tr {
    cursor: pointer;
}

.opened {
    font-weight: bold;

    &+tr {
        cursor: inherit;

        p {
            line-height: 28px;
            padding: 0;
            margin: 0;
        }
    }

    .toggle {
        transform: rotate(225deg);
    }
}

.history {

    &__chnage-order {
        display: flex;
        align-items: center;
        margin-top: 3px;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            border-bottom: 0;
            padding-bottom: 7px;
        }

        p {
            margin: 0;
            font-size: 14px;
            padding-left: 25px;

            @include media-breakpoint-down(md) {
                max-width: 295px;
            }
        }
    }

    &__cancel-order-header {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        // margin-top: 18px;

        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
        }
    }

    &__cancel-order {
        margin-top: 4px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        p {
            margin: 0;
            font-size: 14px;
            max-width: 387px;

            @include media-breakpoint-down(md) {
                max-width: 295px;
            }
        }

        button {
            @include media-breakpoint-down(md) {
                width: 200px;
            }
        }
    }
}

thead {
    font-family: Montserrat;
    color: #9A9A9A;
    font-size: 14px;
    font-weight: 500;

}

.table> :not(:first-child) {
    border-top: 1px solid #E5E5E5 !important;
}

#app>div {

    background: url(../assets/history-bg.svg) 95% 70% no-repeat #F5F5F5;
}

td {
    padding: 20px 0 20px !important;

    @include media-breakpoint-down(md) {
        padding: 10px 0 10px !important;
    }
}

th {
    padding: 0 0 20px !important;
}

.table> :not(caption)>*>* {
    @include media-breakpoint-down(md) {
        border: none;
    }
}

.tableHeader {
    border-bottom: 1px solid #ECECEC;
}

.statusСollected {
    display: block;
    color: #009017;
    font-weight: bold;
}

.statusPayed {
    display: block;
    font-weight: bold;
}

.stars {
    @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    img {
        @include media-breakpoint-down(md) {
            width: 25px;
            height: auto;

        }
    }
}

[data-popover='info'] {

    margin-bottom: 30px;
    //   width: 100% !important;
    //   max-width: 280px !important;
    font-family: Montserrat;
    padding: 21px 15px 33px 21px;
    font-size: 14px;
    line-height: 142.69%;
    /* or 20px */

    color: #000000;

}

.disablerate {
    display: block;

    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

}
.assemled{
    color: #009017;
    font-weight: 700;
}
.payMethod{
    display: inline-block;
    max-width: 120px;
}
